.thanks {
    background-color: #F2FBF7;
    width: 100%;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10rem 0;
    margin-bottom: 50px;
    /* margin-top: 120px; */
}

@media only screen and (max-width: 575px) {
    .thanks {
        margin-top: 120px;
    }
}

.card {
    border: 1px solid #00A963;
    background-color: #00A963;
    color: white;
    border-radius: 20px;
    font-weight: 800;
    padding: 100px;
}

.card h2 {
    margin: 0;
}

@media only screen and (max-width:575px) {
    .card {
        padding: 50px !important;
    }
    .card h2 {
        font-size: 30px;
        line-height: 40px;
    }
}