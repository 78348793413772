.Modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #211913CC;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}

.Card {
  position: relative;
  width: 500px;
  max-width: 90%;
  max-height: 90%;
  background-color: #E5E7EB;
  border-radius: 32px;
  margin-top: 20px;
  padding: 35px;
  z-index: 1000;
  overflow-y: auto;
}

.Close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Card h2 {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #383838;
}

.Divider {
  border: 1px solid #00A963;
  margin: 25px 0;
}