.mainForm {
    height: 100%;
    background-color: white;
    padding: 2rem 3rem;
    border-radius: 20px;
    border: 1px solid #00A963;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


@media only screen and (max-width:450px) {
    .mainForm {
        padding: 2rem 1.2rem;
    }
}


.title {
    font-size: 18px;
    text-align: left;
    margin-bottom: 1rem;
    color: #383838;
}

.title span {
    font-weight: bold;
    color: #383838;
    font-weight: 700;
}

.title p {
    margin: 5px 0 0 0;
    font-style: italic;
    color: #383838;
    font-size: 13px;
    font-weight: 400;
}

.mainForm button {
    width: 100%;
    padding: 15px 0px;
    border-radius: 5px;
    color: white;
    background-color: #FB9F41;
    border: 1px solid #FB9F41;
    outline: none;
    border: none;
}

.mainForm button:hover {
    background-color: #ffa851;
}

.mainForm button:focus {
    background-color: #FB9F41;
}

.mainForm button svg {
    color: white;
    margin-left: 10px;
}

.formFooter p {
    padding-top: 20px;
    color: #000B29;
    font-weight: 400;
}


.mainForm input {
    outline: none;
    padding: 25px 50px;
}

.icon {
    position: absolute;
    z-index: 100;
    padding: 12.5px 15px;
    color: #FB9F41;
}

.mainForm input:focus {
    box-shadow: none;
    border-color: lightgray;
}

.slider {
    max-height: 300px;
    position: relative;
}

.sliderLabel {
    position: absolute;
}