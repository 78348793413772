header,
footer {
    height: 100px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    color: #ffffff;
}

.Refreshbadkamer .slider-label {
    position: absolute;
    width: 150px;
    bottom: 10px;
    right: 10px;
}

.Refreshbadkamer .label-section .star {
    position: absolute;
    bottom: 75px;
    right: 35px;
    z-index: 100;
}

.Refreshbadkamer .share {
    display: flex;
    align-items: center;
}

.Refreshbadkamer .share-link {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-left: 8px;
}

.Refreshbadkamer .divider {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.Refreshbadkamer .divider img {
    width: 50%;
    margin-left: auto;
}

@media only screen and (max-width: 1201px) {
    .Refreshbadkamer .divider img {
        width: 60%;
    }
}


@media only screen and (max-width: 992px) {
    .Refreshbadkamer .divider img {
        width: 60%;
        margin-bottom: 20px;
    }
}

.Refreshbadkamer .refresh-slider {
    height: 350px;
    max-height: 350px;
}

@media only screen and (max-width: 768px) {
    .Refreshbadkamer .refresh-slider {
        height: 350px;
        max-height: 350px;
    }
}

.Refreshbadkamer .reviews {
    display: block;
    width: 85%;
    margin: 0 auto;
}

.Refreshbadkamer .card .label {
    width: 160px;
    transform: rotate(-20deg);
    position: absolute;
    top: -80px;
    left: -20px;
}

.Refreshbadkamer .card .arrow {
    width: 40px;
    position: absolute;
    left: 95px;
    top: -10px;
    z-index: 5;
}

.Refreshbadkamer .card h6 {
    font-size: 20px;
    text-align: center;
    background-color: #F2FBF7;
    color: #383838;
    border-radius: 20px;
    border: 5px solid #00A963;
    padding: 55px 10px 35px 10px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.Refreshbadkamer .card h6 span {
    font-weight: 800;
}

.Refreshbadkamer .cta {
    padding: 70px 0;
    background-color: #F2FBF7;
    width: 100%;
}

.Refreshbadkamer .cta .text-section {
    width: 50%;
}

.Refreshbadkamer .cta .img-section {
    display: flex;
    flex-direction: column;
}

.Refreshbadkamer .cta .img-section label {
    position: absolute;
    background-color: #007BFF;
    padding: 8px 24px;
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
}

.Refreshbadkamer .cta .img-section .top-label {
    top: 25px;
    left: 20px;
}

.Refreshbadkamer .cta .img-section .center-label {
    width: 160px;
    position: absolute;
    top: -40px;
    right: 20px;
}

.Refreshbadkamer .cta .img-section .bottom-label {
    bottom: 17px;
    left: 20px;
}

.Refreshbadkamer .cta img {
    width: 400px;
}

@media only screen and (max-width: 992px) {
    .Refreshbadkamer .cta .text-section {
        width: 100%;
    }

    .Refreshbadkamer .cta .img-section {
        width: 100%;
        margin-top: 70px;
        align-items: center;
    }
}

@media only screen and (max-width: 575px) {
    .Refreshbadkamer .cta .text-section {
        width: 90%;
    }

    .Refreshbadkamer .cta img {
        max-width: 350px;
    }
}

@media only screen and (max-width: 420px) {
    .Refreshbadkamer .cta img {
        max-width: 280px;
    }
}

.Refreshbadkamer .cta h2 {
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
}

.Refreshbadkamer .cta p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
}


.Refreshbadkamer .cta span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
}

.Refreshbadkamer .cta button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #FB9F41;
    border: 0;
    outline: 0;
    border-radius: 8px;
    padding: 12px 30px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
}

.Refreshbadkamer .cta button:active {
    background-color: #ffa851 !important;
    outline: 0;
}

.Refreshbadkamer .cta button:hover {
    background-color: #ffa851;
    outline: 0;
}

.Refreshbadkamer .cta button:focus {
    background-color: #FB9F41;
    outline: 0;
}

.Refreshbadkamer .refresh-features {
    margin-top: 5rem;
}

.Refreshbadkamer .refresh-features h1 {
    padding: 0 50px;
    font-family: Montserrat;
    color: #383838;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

@media only screen and (max-width: 575px) {
    .Refreshbadkamer .refresh-features h1 {
        padding: 0 20px;
    }
}

.Refreshbadkamer .refresh-features span {
    color: #007BFF;
}

.Refreshbadkamer .refresh-features .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    color: #FFFFFF;
    background-color: #007BFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.Refreshbadkamer .refresh-features .section {
    /* display: flex;
    align-items: start; */
    /* gap: 50px; */
    /* margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center; */
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    /* gap: 20px; */
}

.Refreshbadkamer .refresh-features .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #383838;
    margin-top: 20px;
}