.stepCard {
    background-color: #00A963;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 2rem 1.5rem;
    color: white;
    border-radius: 20px;
}

.label {
    display: inline-block;
    background-color: white;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #00A963;
    padding: 8px 18px;
    margin: 10px 0;
}

.stepCard h2 {
    font-weight: 700;
    font-size: 33px;
    line-height: 45px;
}

@media only screen and (min-width: 991px) {
    .parisResponsiveness {
        line-height: 35px !important;
        font-size: 28px !important;
    }

    .question {
        margin: 0;
    }
}

@media (min-width: 576px) and (max-width: 764px) {
    .parisResponsiveness {
        line-height: 35px !important;
        font-size: 28px !important;
    }

    .question {
        margin: 0;
    }
}

.refreshHeader {
    line-height: 32px !important;
    font-size: 23px !important;
}

.refreshLabel {
    display: inline-block;
    background-color: white;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #00A963;
    padding: 8px 18px;
    margin: 10px 0;
}

@media only screen and (min-width: 768px) {
    .refreshHeader {
        line-height: 38px !important;
        font-size: 30px !important;
    }
}

@media only screen and (min-width: 991px) {
    .refreshHeader {
        line-height: 27px !important;
        font-size: 20px !important;
    }
    .refreshLabel {
        font-size: 12px;
        padding: 8px 12px;
        margin: 10px 0;
    }
}

@media only screen and (min-width: 1001px) {
    .refreshHeader {
        line-height: 32px !important;
        font-size: 23px !important;
    }
    .refreshLabel {
        font-size: 13px;
        padding: 8px 15px;
        margin: 10px 0;
    }
}

.steps {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.step img {
    background-color: white;
    width: 40px;
    height: 40px;
    padding: 2px;
    border-radius: 8px;
}

.step {
    display: flex;
    gap: 20px;
}

.step h6 {
    font-weight: 700;
    margin: 0;
    margin-bottom: 4px;
}

.step p {
    font-size: 13px;
    font-weight: 400;
}

.leafLabels {
    position: absolute;
    top: -4%;
    right: 5%;
}

@media only screen and (max-width: 576px) {
    .leafLabels {
        top: -3%;
        right: 5%;
    }
}

@media only screen and (max-width: 395px) {
    .leafLabels {
        top: -2%;
        right: 6%;
    }
}

.question {
    font-weight: 500;
}