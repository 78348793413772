.header {
    width: 100%;
    height: 150px;
    display: flex;
    gap: 2rem;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media only screen and (max-width:576px) {
    .header {
        padding: 0 1.2rem;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 85px;
    }
}

@media only screen and (max-width:770px) {
    .header div {
        flex-direction: column;
    }
}

.header img {
    width: 180px;
}

.header div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.header div p {
    font-weight: 700;
    margin: 0;
    color: '#383838';
}

.header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
}

.header ul li {
    background-color: #f2fbf7;
    color: #383838;
    font-weight: 400;
    font-size: 90%;
    display: flex;
    flex-direction: column;
    border: 1.09px solid #00A963;
    border-radius: 10px;
    margin: auto;
    text-align: center;
    justify-content: center;
    width: 90px;
    height: 60px;
}

.header ul li span {
    color: #00A963;
    font-weight: bold;
    font-size: 120%;
}

@media only screen and (max-width:400px) {
    .header ul {
        gap: 0.5rem;
    }

    /* .header ul li {
        width: 55px;
        height: 55px;
    } */
}

@media (min-width: 769px) and (max-width: 1200px) {
    .header ul {
        gap: 0.5rem;
    }
}

@media only screen and (min-width:576px) {
    .mobileText {
        display: none;
    }
}

@media only screen and (max-width:576px) {
    .desktopText {
        display: none;
    }
}

.dateSection {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.date {
    border: 1.09px solid #00A963;
    background-color: #F2FBF7;
    padding: 8px 15px;
    border-radius: 18px;
}


.date span {
    color: #00A963;
    font-weight: bold;
}