.simpleCards {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    gap: 25px;
}

.slider {
    object-fit: cover;
    height: 320px;
    max-height: 320px;
    min-width: 100%;
    max-width: 100%;
    border-radius: 20px;
}

.shoptegoedSlide {
    background-color: #F2FBF7;
    height: 320px;
    max-height: 320px;
    min-width: 100%;
    max-width: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shoptegoedSlide .slide {
    object-fit: cover;
    height: 280px;
    max-height: 280px;
    min-width: 100%;
    max-width: 100%;
}

.shoptegoedSlide .Label {
    position: absolute;
    top: 90;
    margin-right: 210px;
}

.shoptegoedSlide .Label img {
    width: 100px;
    height: 100px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .shoptegoedSlide .Label img {
        width: 80px;
        height: 80px;
        margin-right: -30px;

    }
}

@media only screen and (max-width: 575px) {
    .shoptegoedSlide .slide {
        object-fit: contain;
    }
}

@media only screen and (max-width: 464px) {
    .shoptegoedSlide .slide {
        object-fit: cover;
    }
}

.simpleCards h5 {
    text-align: center;
    background-color: #F2FBF7;
    color: #383838;
    border-radius: 20px;
    border: 5px solid #00A963;
    padding: 40px 15px;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
}

.simpleCards h5 span {
    font-size: 130%;
    font-weight: 800;
}

.sliderLabel {
    position: absolute;
    top: 38%;
    right: 32%;
    transform: rotate(-10deg);
    scale: 60%;
}

.teddyLabel {
    position: absolute;
    top: 57px;
    left: -20px;
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 1195px) {
    .sliderLabel {
        top: 35%;
        right: 20%;
    }
}

@media only screen and (max-width: 992px) {
    .sliderLabel {
        top: 40%;
        right: 45%;
    }
    .teddyLabel {
        left: 5px;
    }
}

@media only screen and (max-width: 768px) {
    .sliderLabel {
        top: 40%;
        right: 25%;
    }
    .teddyLabel {
        left: -20px;
    }
}

@media only screen and (max-width: 575px) {
    .sliderLabel {
        top: 40%;
        right: 55%;
    }
    .teddyLabel {
        top: 25px;
        left: 30px;
        width: 120px;
        height: 120px;
    }
}

@media only screen and (max-width: 450px) {
    .sliderLabel {
        top: 40%;
        right: 45%;
    }
    .teddyLabel {
        top: 45px;
        left: 10px;
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 378px) {
    .teddyLabel {
        top: 55px;
        left: -5%;
    }
}

.sliderLabel2 {
    position: absolute;
    top: -12%;
    right: 32%;
    transform: rotate(-10deg);
    scale: 60%;
}

@media only screen and (max-width: 1195px) {
    .sliderLabel2 {
        top: -10%;
        right: 20%;
    }
}

@media only screen and (max-width: 992px) {
    .sliderLabel2 {
        top: -15%;
        right: 45%;
    }
}

@media only screen and (max-width: 768px) {
    .sliderLabel2 {
        top: -15%;
        right: 25%;
    }
}

@media only screen and (max-width: 575px) {
    .sliderLabel2 {
        top: -15%;
        right: 55%;
    }
}

@media only screen and (max-width: 450px) {
    .sliderLabel2 {
        top: -15%;
        right: 40%;
    }
}

.Divider {
    position: relative;
}

.Divider>img {
    scale: 60%;
    width: 100%;
    margin: 0 auto;
}

.leafLabels {
    position: absolute;
    transform: rotate(45deg);
    top: 30%;
    left: -5%;
}

@media only screen and (max-width: 1196px) {
    .leafLabels {
        top: 40%;
    }
}


@media only screen and (max-width: 992px) {
    .leafLabels {
        left: 0%;
    }
}

@media only screen and (max-width: 768px) {
    .leafLabels {
        left: -2%;
    }
}

.leafLabels img:first-child {
    width: 60px;
    height: 60px;
    transform: rotate(-50deg);
}

.leafLabels img {
    width: 45px;
    height: 45px;
    transform: rotate(-90deg);
}

.label_2 {
    position: absolute;
    top: -75%;
    left: 24%;
    scale: 60%;
}


@media only screen and (max-width: 1201px) {
    .label_2 {
        top: -55%;
        left: 10%;
    }
}


@media only screen and (max-width: 992px) {
    .label_2 {
        top: -75%;
        left: 40%;
    }
}

@media only screen and (max-width: 768px) {
    .label_2 {
        top: -65%;
        left: 20%;
    }
}

@media only screen and (max-width: 575px) {
    .label_2 {
        top: -95%;
        left: 50%;
    }
}

@media only screen and (max-width: 444px) {
    .label_2 {
        top: -75%;
        left: 35%;
    }
}

@media only screen and (max-width: 360px) {
    .label_2 {
        top: -75%;
        left: 25%;
    }
}

@media only screen and (max-width: 335px) {
    .label_2 {
        top: -65%;
        left: 20%;
    }
}

.dots {
    text-align: center;
    position: absolute;
    bottom: 3%;
    left: calc(50% - 18px);
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
}

.dot.active {
    background-color: darkgray;
}

.Arrow {
    text-align: center;
    margin-right: 17px;
}

.greenBorder {
    border: 1px solid #00A963;
}

.topArea {
    display: flex;
    flex-direction: column;
    gap: 35px;
}