.featureSection {
    background-color: #F2FBF7;
    padding: 5rem 2rem;
}

.featureSection h1 {
    text-align: center;
    color: #383838;
    font-weight: 700;
    margin-bottom: 45px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.feature p {
    color: #A7A7A7;
    font-weight: 400;
    font-size: 15px;
}

.feature img {
    width: 50px;
    height: 50px;
}