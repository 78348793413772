/* Header */
.header {
  width: 100%;
  height: 150px;
  display: flex;
  gap: 2rem;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 10px;
}


@media only screen and (max-width:770px) {
  .dateSection {
    flex-direction: column;
  }
}

.header img {
  width: 180px;
}

.dateSection {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media only screen and (max-width:576px) {
  .header {
    padding: 0 1.2rem;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 85px;
  }
}

.header div p {
  font-weight: 700;
  margin: 0;
  color: '#383838';
}

.date {
  border: 1.09px solid #00A963;
  background-color: #F2FBF7;
  padding: 8px 15px;
  border-radius: 18px;
}

.date span {
  color: #00A963;
  font-weight: bold;
  /* font-size: 110%; */
}

.separate {
  border-left: 1px solid black;
}

.stepCard {
  background-color: #00A963;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 1rem 1.5rem;
  color: white;
  border-radius: 20px;
}

.label {
  display: inline-block;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #00A963;
  padding: 8px 18px;
  margin: 10px 0;
}

@media only screen and (max-width: 400px) {
  .label {
    margin-top: 25px;
  }
}

.stepCard h2 {
  font-weight: 700;
  font-size: 38px;
  line-height: 35px;
}

.stepCard h3 {
  font-size: 26px;
  font-weight: 500;
}


@media only screen and (max-width: 991px) {
  .stepCard h2 {
    line-height: 45px;
  }
}

@media only screen and (min-width: 991px) {
  .parisResponsiveness {
    line-height: 35px !important;
    font-size: 28px !important;
  }

  .question {
    margin: 0;
  }
}

@media (min-width: 576px) and (max-width: 764px) {
  .parisResponsiveness {
    line-height: 35px !important;
    font-size: 28px !important;
  }

  .question {
    margin: 0;
  }
}

.refreshHeader {
  line-height: 32px !important;
  font-size: 23px !important;
}

.refreshLabel {
  display: inline-block;
  background-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #00A963;
  padding: 8px 18px;
  margin: 10px 0;
}

@media only screen and (min-width: 768px) {
  .refreshHeader {
    line-height: 38px !important;
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 991px) {
  .refreshHeader {
    line-height: 27px !important;
    font-size: 20px !important;
  }

  .refreshLabel {
    font-size: 12px;
    padding: 8px 12px;
    margin: 10px 0;
  }
}

@media only screen and (min-width: 1001px) {
  .refreshHeader {
    line-height: 32px !important;
    font-size: 23px !important;
  }

  .refreshLabel {
    font-size: 13px;
    padding: 8px 15px;
    margin: 10px 0;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.step img {
  background-color: white;
  width: 40px;
  height: 40px;
  padding: 2px;
  border-radius: 8px;
}

.step {
  display: flex;
  gap: 20px;
}

.step h6 {
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
}

.step p {
  font-size: 13px;
  font-weight: 400;
}

.leafLabels {
  position: absolute;
  top: -4%;
  right: 5%;
}

@media only screen and (max-width: 576px) {
  .leafLabels {
    top: -3%;
    right: 5%;
  }
}

@media only screen and (max-width: 395px) {
  .leafLabels {
    top: -3%;
    right: 6%;
  }
}

.question {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

/* --------- FORM ---------- */

.mainForm {
  height: 100%;
  background-color: #F2FBF7;
  padding: 2rem 3rem;
  border-radius: 20px;
  border: 1px solid #00A963;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width:450px) {
  .mainForm {
    padding: 2rem 1.2rem;
  }
}


.title {
  color: #383838;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.title span {
  font-weight: bold;
  color: #383838;
  font-weight: 700;
}

.title p {
  margin: 5px 0 0 0;
  font-style: italic;
  color: #383838;
  font-size: 13px;
  font-weight: 400;
}

.mainForm button {
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
  color: white;
  background-color: #FB9F41;
  border: 1px solid #FB9F41;
  outline: none;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.mainForm button:hover {
  background-color: #ffa851;
}

.mainForm button:focus {
  background-color: #FB9F41;
}

.mainForm button svg {
  color: white;
  margin-left: 10px;
}

.formFooter span {
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  color: #383838;
}

.formFooter p {
  padding-top: 5px !important;
  padding-top: 20px;
  color: #000B29;
  font-weight: 400;
}


.mainForm input {
  outline: none;
  padding: 25px 50px;
}

.mainForm input::placeholder {
  font-family: Montserrat;
  font-weight: 400;
  color: #A7A7A7;

}


.icon {
  position: absolute;
  z-index: 100;
  padding: 12.5px 15px;
  color: #FB9F41;
}

.mainForm input:focus {
  box-shadow: none;
  border-color: lightgray;
}

.slider {
  max-height: 400px;
  min-height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.sliderLabel {
  position: absolute;
}

/* Bottom Box */

.box {
  border-radius: 32px;
  border: 1px solid #00A963;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

@media only screen and (max-width:991px) {
  .box {
    display: none;
  }
}

.boxLogo {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 25px;
  color: white;
  background-color: #FB9F41;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  transform: rotate(-5deg);
}

.boxText {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.boxText span {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 800;
}

.boxArrow {
  position: absolute;
  top: -20px;
}

/* --------------------- */

.mainSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.dots {
  text-align: center;
  position: absolute;
  bottom: 3%;
  left: calc(50% - 18px);
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
}

.dot.active {
  background-color: #00A963;
}

.scrollToForm {
  position: fixed;
  bottom: 10px;
  padding: 0 20px;
  width: 100%;
}

.scrollBtn {
  width: 100%;
  padding: 12px 0px;
  border-radius: 5px;
  color: white;
  background-color: #FB9F41;
  outline: none;
  border: none;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  border-radius: 30px;
}

@media only screen and (min-width: 575px) {
  .scrollToForm {
    display: none;
  }

  .spacing {
    display: none;
  }
}