.featureSection {
    background-color: #F2FBF7;
    margin-top: 5rem;
    padding: 5rem 2rem;
}

.featureSection h1 {
    text-align: center;
    color: #383838;
    font-weight: 700;
    margin-bottom: 45px;
}

.features {
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .features {
        flex-direction: column;
        gap: 50px;
    }
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    background-color: #FFFFFF;
    border-radius: 32px;
    padding: 25px;
}

@media only screen and (min-width: 768px) {
    .feature {
        max-width: 300px !important;
    }
}

.feature p {
    color: #383838 !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.feature h6 {
    color: #383838 !important;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
}

.line {
    width: 75%;
    position: absolute;
    margin: 0 auto;
    border-top: 2px dashed #00A963;
}

@media only screen and (max-width: 768px) {
    .line {
        width: 1px;
        height: 100%;
        position: absolute;
        margin: 0 auto;
        border-left: 2px dashed #00A963;
    }
}