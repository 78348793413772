.Footer {
  width: 100%;
  /* height: 180px; */
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  text-align: center;
}

.Footer img {
  width: 130px;
}

.Footer p {
  color: #C1C1C1;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

/* @media only screen and (max-width: 550px) { */
@media only screen and (max-width: 992px) {
  .Footer img {
    width: 150px;
    margin-bottom: 10px;
  }
  .Footer {
    /* display: flex; */
    flex-direction: column;
    gap: 15px;
  }
}

.Footer span {
  text-decoration: underline;
  cursor: pointer;
}

.Footer span:hover {
  filter: brightness(80%);
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  width: 80%;
  height: 80%;
  background-color: #fff;
  /* padding: 20px 50px; */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalContent h4 {
  padding: 20px;
  /* margin-bottom: 20px; */
  margin-bottom: 0;
  font-weight: 600;
  border-bottom: 1px solid #00A963;
}

.privacyText {
  height: 70%;
  padding: 20px;
  overflow-y: scroll;

}

.circularList {
  list-style-type: circle;
}

.privacyText span {
  font-weight: bold;
}

.privacyFooter {
  height: 80px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding: 0 20px;
  border-top: 1px solid #00A963;
  background-color: white;
}

.privacyFooter button {
  height: 40px;
  background-color: #00A963;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0px 12px;
  color: white;
  font-weight: 500;
  float: right;
}